import Loader from "../../Alertwindow/Loader";
import useFetch from "../../hooks/useFetch";
import "./featured.css";

const Featured = () => {
  const { data, loading, error } = useFetch(
    "https://stayback1.onrender.com/api/hotels/countByCity?cities=jaipur,kota,Delhi,Noida"
  );

  return (
    <div className="featured  ">
      <div className="row ">

        {loading ? (
          <Loader/>
        ) : (
          <>
            <div className="container settlement hotelImages horizontal-scrolling-items">

              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item ">
                <img
                  src="images/jaipur.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Jaipur</h1>
                  <h2>{data[0]} properties</h2>
                </div>
              </div>

              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="Images/kota.jfif"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Kota</h1>
                  <h2>{data[1]} properties</h2>
                </div>
              </div>
              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="images/delhi.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Delhi</h1>
                  <h2>{data[2]} properties</h2>
                </div>
              </div>
              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="images/delhi.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Noida</h1>
                  <h2>{data[3]} properties</h2>
                </div>
              </div>
             
              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="images/delhi.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Noida</h1>
                  <h2>{data[4]} properties</h2>
                </div>
              </div>
                          
              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="images/delhi.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Noida</h1>
                  <h2>{data[4]} properties</h2>
                </div>
              </div>
                          
              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="images/delhi.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Noida</h1>
                  <h2>{data[4]} properties</h2>
                </div>
              </div>
                          
              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="images/delhi.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Noida</h1>
                  <h2>{data[4]} properties</h2>
                </div>
              </div>
                          
              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="images/delhi.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Noida</h1>
                  <h2>{data[4]} properties</h2>
                </div>
              </div>
                          
              <div className="featuredItem  hotelImgWrapper horizontal-scrolling-items__item">
                <img
                  src="images/delhi.jpg"
                  alt=""
                  className="featuredImg"
                />
                <div className="featuredTitles">
                  <h1>Noida</h1>
                  <h2>{data[4]} properties</h2>
                </div>
              </div>
                          
              
                          
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Featured;
