import React from 'react'

function Singlebook({ book }) {
  console.log("booking id ", book)
  return (
    <div>
      <h1 className='mt-5 py-5 alert alert-success'>
        Congratulation your<br/> 
        Booking hase been Confirmed

      </h1>
    </div>
  )
}

export default Singlebook