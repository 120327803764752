import React, { useContext, useState } from 'react';
import './signup.css';
import { useNavigate, Link } from 'react-router-dom';

import Headers from '../../components/header/Headers';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Signup() {
  const [user, setUser] = useState({
    username: '',
    phone: '',
    email: '',
    password: '',

  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  console.log("tser data is us", user)

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;

  const handleInput = (e) => {
    const { name, value, type, checked } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    setIsSubmitted(false);
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const Verify = () => {
    let localErrors = {};
    let isValid = true;

    const addErrMsg = (msg, key) => {
      localErrors[key] = msg;
      isValid = false;
    };

    if (!user.username) {
      addErrMsg('Name is required', 'username');
    } else if (user.username.length < 2) {
      addErrMsg('Name should be at least 2 characters', 'username');
    }

    if (!user.phone) {
      addErrMsg('Contact number is required', 'phone');
    } else if (user.phone.length < 10) {
      addErrMsg('Contact number should be at least 10 digits', 'phone');
    }

    if (!user.email) {
      addErrMsg('Email is required', 'email');
    } else if (!emailRegex.test(user.email)) {
      addErrMsg('Invalid email format', 'email');
    }

    if (!user.password) {
      addErrMsg('Password is required', 'password');
    } else if (user.password.length < 8) {
      addErrMsg('Password should be at least 8 characters', 'password');
    } else if (!passwordRegex.test(user.password)) {
      addErrMsg('Password should contain at least one number and one uppercase letter', 'password');
    }


    if (!user.terms) {
      addErrMsg('You must accept the terms and conditions', 'terms');
    }

    setErrors(localErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!Verify()) {
      return;
    }

    try {
      await axios.post('https://stayback1.onrender.com/api/auth/register', user);

      toast.success("Signup Success...");

      navigate('/login');
    } catch (error) {
      console.error('Error during signup', error);
      toast.error('Signup failed. Please try again....');
    }
  };

  return (
    <>
      <Headers />
      <div className='userbackground'>
        <div className=' signmain'>

          <h1 className='setsign'>Sign up</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-1 rounded">
              <label htmlFor="username" className="form-label">Name</label>
              <input
                type="text"
                className="form-control mx-3 "
                id="username"
                name='username'
                onChange={handleInput}
                value={user.username}
                placeholder='Enter your Name'
              />
              {isSubmitted && errors.username && <p className='text-danger'>{errors.username}</p>}
            </div>
            <div className="mb-1 rounded">
              <label htmlFor="email" className="form-label">Email address</label>
              <input
                type="email"
                className="form-control mx-3 "
                id="email"
                name='email'
                onChange={handleInput}
                value={user.email}
                placeholder='Enter Email'
              />
              {isSubmitted && errors.email && <p className='text-danger'>{errors.email}</p>}
            </div>

            <div className="mb-1 rounded">
              <label htmlFor="phone" className="form-label">Contact No</label>
              <input
                type="tel"
                className="form-control mx-3 "
                id="phone"
                name='phone'
                onChange={handleInput}
                value={user.phone}
                placeholder='Enter Contact No'
              />
              {isSubmitted && errors.phone && <p className='text-danger'>{errors.phone}</p>}
            </div>

            <div className="mb-1 rounded">
              <label htmlFor='password'>Password</label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  placeholder="Enter password"
                  id="password"
                  onChange={handleInput}
                  value={user.password}
                  className="form-control m-3"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}

                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    border: 'none',
                    background: 'transparent',
                    cursor: 'pointer'
                  }}
                >
                  {showPassword ? <img src='images/eye-slash.svg' /> : <img src='images/eye.svg' />}
                </button>
              </div>
              {isSubmitted && errors.password && <p className='text-danger'>{errors.password}</p>}
            </div>

            <div className="mb-1 rounded">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="terms"
                  name='terms'
                  onChange={handleInput}
                  checked={user.terms}
                />
                <label htmlFor="terms" className="form-check-label">I accept the terms and conditions</label>
              </div>
              {isSubmitted && errors.terms && <p className='text-danger'>{errors.terms}</p>}
            </div>

            <button type="submit" className="btn btn-primary">Sign Up</button>
          </form>
          <div className='mt-3'>
            <span className='text-secondary' >
              All Ready have Account
              &ensp; <Link className="text-primary" to='/login'>
                Login Here
              </Link>
            </span>
          </div>
        </div>
      </div>

    </>
  )
}

export default Signup;
