import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Headers from '../header/Headers';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AllBooking() {
    const { user } = useContext(AuthContext);
    const [booking, setBooking] = useState([]);

    useEffect(() => {
        const handleBook = async () => {
            try {
                const res = await axios.get(`https://stayback1.onrender.com/api/booking/user/${user._id}`);
                setBooking(res.data);
                toast.success("All Booking Fetched");
            } catch (error) {
                toast.error("Failed to fetch bookings!");
                console.error("Something went wrong!", error);
            }
        };

        handleBook();
    }, [user._id]);

    if (booking.length === 0) {
        return (
            <div className='container'>
                <Link to="/">
                    <h3 className='setno'>
                        <img className='imgse' src='images/books.gif' alt="No Bookings" />
                        <p className='alert alert-warning text-center'>You have not booked any hotels yet!</p>
                    </h3>
                </Link>
            </div>
        );
    }

    return (
        <>
            <Headers />
        {booking.map(newBooking => (
             <div className='container bord border border-secondary ' >
  <div className='row'>
                    <div className='col-md-3 text-center  '>
                            <img src='images/logo.PNG' className='booking' alt='Company Logo' />
                        </div>
                   
                      
                        <div className="col-md-5 text-center setex">
                            
                                <Link className="me-4 logcol" to="https://www.facebook.com/share/awQaug6PfNs1drif/?mibextid=qi2Omg" target="_blank"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="logcol" to="https://x.com/stay_menu">
                                    <img src='/images/xx.jpg' width={25} alt='Twitter' />
                                </Link>
                                <Link className="mx-4 logcol" to="https://www.instagram.com/staymenu_?igsh=MW1lc3dpZnJnYTI0dg==" target="_blank"><i className="fab fa-instagram"></i></Link>
                                <Link className="logcol" to="" target="_blank"><i className="fab fa-youtube"></i></Link>
                            
                        </div>
                        <div className=' col-md-4 setex'>

                            <p className='setex text-center'>GSTIN: 34323412xxxxx</p>
                        </div>
                                    </div>
                

                <div className='row'>
                    <div className='col-md-12'>
                        <ul className='ms-4'>
                            {newBooking.hotels.map(hotel => (
                                <li key={hotel.name}>
                                    <span className='hotname'>{hotel.name} </span><br />
                                    <div className='col-md-6'>
                                        <h6 className='sethed'> User Information</h6>
                                        {newBooking.user.map(user => (
                                            <div className='ms-5' key={user._id}>
                                                <p><strong>Customer Name:</strong> {user.username}</p>
                                                <p ><strong>Email:</strong> {user.email}</p>
                                                <p ><strong>Phone:</strong> {user.phone}</p>

                                            </div>
                                        ))}
                                    </div>
                                    <strong className='mx-5 p-5'>Type:&ensp; {hotel.type}</strong>
                                    <strong className='mx-5 p-5'>City:&ensp; {hotel.city}</strong>
                                    <strong className='mx-5 p-5'>Address:&ensp; {hotel.address}</strong>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <hr />

                    <div className='row'>
                        <div className='col-md-6'>
                            <h6 className='sethed'>Dates</h6>
                            <ul className='ms-4'>
                                {newBooking.dates.map(date => (
                                    <li key={date._id}>
                                        <strong>Check In Date:</strong> {new Date(date.startDate).toLocaleString()}<br />
                                        <strong>Check Out Date:</strong> {new Date(date.endDate).toLocaleString()}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='col-md-6'>
                            <h6 className='sethed'>Selected Rooms</h6>
                            <ul className='ms-4'>
                                {newBooking.selectedRooms.map(roomId => (
                                    <li key={roomId}>{roomId}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <h6 className='sethed'>Options</h6>
                    <ul>
                        {newBooking.options.map(option => (
                            <li className='ms-4' key={option._id}>
                                <strong>Adults:</strong> {option.adult}<br />
                                <strong>Children:</strong> {option.children}<br />
                                <strong>Rooms:</strong> {option.room}
                            </li>
                        ))}
                    </ul>
                    <h6 className='sethed'>Payment Information</h6>
                    {/* <ul className='ms-4'>
                        {newBooking.hotels.map(payment => (
                            <li key={payment}>
                                <strong>Rent Per Day :</strong>{payment.cheapestPrice}<br />
                                <strong>Total Day :</strong>{days}<br />
                                <strong>Total Rent :</strong>{days * payment.cheapestPrice * options.room} <br />
                                <strong>GSTIN :</strong>{days * payment.cheapestPrice * options.room * 12 / 100}<br />
                                <strong>Grand Total  :</strong>{days * payment.cheapestPrice * options.room + days * payment.cheapestPrice * options.room * 12 / 100}
                            </li>
                        ))}
                    </ul> */}
                    <div className='alert alert-secondary ps-5'>
                        <p><strong>Status:</strong> {newBooking.bookingStatus}</p>
                        <p><strong>Booking ID:</strong> {newBooking._id}</p>
                    </div>
                    <button className='btn submithandle'>Pay Now</button>
                </div>
            </div >
))}
        </>
    );
}

export default AllBooking;
